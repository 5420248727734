<template>
  <v-card tile width="475px" class="card-border">
    <v-container class="on-mobile" style="padding-top: 25px; text-align: center; min-height: 514px;">
      <v-col align-self="center">
        <div style="margin-bottom: 5px;" v-html="getReferralBonusText" class="header-text-bold"></div>
        <div style="margin-bottom: 10px;" class="header-text" v-html="getReferralHeaderText"></div>
        <slot/>
      </v-col>
    </v-container>
    <div
        v-if="showFooter"
        class="empty-div"
        style="width: 100%; height: 100px; bottom: 0px; position: relative; padding-top: 20px;"
    ></div>
    <div
        v-if="showFooter"
        class="footer-style"
    >
      <span v-if="showDirectRefer" style="font-family: Roboto; font-size: 18px; color: #fff;">
        {{ $t("referral.referPersonally") }}
        <a style="font-weight: bold;color: #ffcb15;" href="/refer-form">{{ $t("referral.checkReferralLinkPart2") }}</a>
      </span>
      <br/>
      <span style="font-family: Roboto; font-size: 18px; color: #fff;">
        {{ $t("referral.checkReferralLinkPart1") }}
        <a style="font-weight: bold;color: #ffcb15;" href="/referralreport">{{$t("referral.checkReferralLinkPart2") }}</a>
      </span>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "ReferralCard",
  props: ["showFooter", "showDirectRefer", "referralConfig", "showDefaultText"],
  data() {
    return {
      languages: ["eng", "বাংলা"],
      lang: this.$i18n.locale || "বাংলা",
      demo: "",
      defaultBonusText: {
        en: "",
        bn: ""
      },
      defaultHeaderText: {
        en: "<p style='font-weight:500;color:#000;margin-bottom: 0 !important;'> Refer Merchants to bKash Business Dashboard</p>",
        bn: "<p style='font-weight:500;color:#000;margin-bottom: 0 !important;'> আপনার পরিচিত মার্চেন্টদের বিকাশ ব্যবসা ড্যাশবোর্ডে রেফার করুন</p>"
      }
    };
  },
  methods: {
    changeLang(language) {
      this.lang = language;
      this.$i18n.locale = language;
      localStorage.setItem("lang", language);
    },
  },
  computed: {
    getLang() {
      return this.$i18n.locale === 'বাংলা' ? 'bn' : 'en';
    },
    getReferralBonusText() {
      if (this.showDefaultText) {
        return this.defaultBonusText[this.getLang];
      }
      return this.referralConfig.referralTextConfig.referralBonusText[this.getLang];
    },
    getReferralHeaderText() {
      if (this.showDefaultText) {
        return this.defaultHeaderText[this.getLang];
      }
      return this.referralConfig.referralTextConfig.referralHeaderText[this.getLang];
    }
  },
};
</script>

<style scoped>
.auth-head-layout {
  display: flex;
  flex-direction: column;
}

.drop-down-arrow {
  height: 27px;
  width: 29px;
  cursor: pointer;
}

.language-text {
  text-transform: capitalize !important;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  margin-top: 5px;
}

.header-text {
  font-family: Helvetica;
  font-size: 27px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.header-text-bold {
  font-family: HindSiliguri;
  font-size: 38px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e2136e;
}

.footer-style {
  width: 100%;
  background-color: #e2136e;
  height: 100px;
  bottom: 0px;
  position: absolute;
  padding-top: 20px;
}

.card-border {
  border: 1px solid #95989a;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14),
  0 1px 8px 0 rgba(0, 0, 0, 0.12) !important;
}

@media only screen and (max-width: 450px) {
  .card-border {
    border: none;
    box-shadow: none !important;
  }

  .header-text-bold {
    font-size: 26px;
  }

  .header-text {
    font-size: 20px;
  }

  .footer-style {
    position: relative;
  }

  .empty-div {
    display: none;
  }

  .on-mobile {
    padding-top: 0;
  }
}
</style>
